const production = process.env.NODE_ENV === "production";

const environment = {
  production: production,
  company: process.env.REACT_APP_COMPANY,
  address: process.env.REACT_APP_ADDRESS,
  mobile: process.env.REACT_APP_MOBILE,
  copyright: process.env.REACT_APP_COPYRIGHT,
  app: process.env.REACT_APP_APP,
  version: process.env.REACT_APP_VERSION,
  url: process.env.REACT_APP_API_URL,
  socketPath: process.env.REACT_APP_SOCKET_PATH,
  storage: process.env.REACT_APP_STORAGE,
  mapbox: process.env.REACT_APP_MAPBOX_KEY,
};

module.exports = environment;
